


.paypal_flex{
    width: 20%;
    display: flex;
    align-items: center;
    z-index: 1;
    margin-left: 15px;
    margin-top: 20px;
}


.succsesfullMessage{
    background-color: rgb(101, 167, 101);
    border: 1px solid rgb(28, 151, 28);
    width: 200px;
    height: 70px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.succsesfullCenter{
    width: 100%;
    display: flex;
    justify-content: center;
}

.addToCart{
    margin-left: 25px;
    height: 50px;
    margin-top: -35px;
    width: 90px;
    border-radius: 5px;
    border: none;
    background-color: rgb(54, 124, 124);
    color: white;
}


@media (max-width:590px){
    .paypal_flex{
        justify-content: center;
    }
}