

.flex_column{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.second_input{
    margin-top: 15px;
}

.image_uploaded_text{
    color: rgb(21, 224, 21);
    font-size: 18px;
}
.image_dont_uploaded{
    color: red;
    font-size: 18px;
}
.saveButton{
    cursor: pointer;
    margin-top: 25px;
}
.edit_input{
    height: 40px;
    margin-top: 15px;
}


.editedImages{
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
}

.imageMiniForEdit{
    width: 100%;
    margin-left: 15px;
    margin-top: 10px;
    height: 80px;
}


.imageBlockForEdit{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

