

.image_div_content{
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.image_div_content img{
    width: 50%;
    height: 100%;
}