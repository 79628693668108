
.reviewPopup{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.322);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1111;
    position: fixed;
}


.popup2{
    width: 400px;
    height: 347px;
    background-color: #3d426d;
    border-radius: 10px;
    opacity: 1.3 !important;
    position: fixed;
    display: flex;
    flex-direction: column;
}

.topPart2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 27%;
}
.logoImg2{
    width: 15%;
    height: 70%;
}
.logoText2{
    color: #DBDEE3;
    font-size: 22px;
    line-height: 1px;
    font-family: monospace;
    font-weight: 700;
    margin-left: 20px;
}
.centerPart2{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.closeImg2{
    width: 10%;
    height: 29%;
    align-self: flex-start;
    justify-self: flex-end;
    margin-right: -8px;
    filter: invert(75%);
    cursor: pointer;
}
.closeImg2 img{
    cursor: pointer;
}
.centerFlex2{

    width: 100%;
    height: 100%;
}

.popup2Part{
    display: flex;
    width: 100%;
    height: 70%;
    justify-content: center;
    align-items: center;
}

.reviewTextarea{
    width: 80%;
    height: 80%;
    resize: none;
}

.bottomReviewPart{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.reviewButton{
    background-color: #445A6D;
    margin-top: 5px;
    cursor: pointer;
    margin-left: 137px;
    width: 86px;
    height: 40px;
    color: white;
}

.requiredStars{
    margin-left: 40px;
    color: red;
}


.requiredTextarea{
    margin-left: 40px;
    color: red;
}
.reviewBlockSuccsesfull{
    display: flex;
    width: 100%;
    justify-content: center;
}

.reviewBlockSuccsesfull span{
    color: rgb(53, 201, 53);
}