.cartMain{
    min-height: 100vh;
    height: 100%;
}

.totalPriceDiv{
    display: flex;
    width: 100%;
    justify-content: center;


}
.totalPriceDiv h1{
    color: white;
}

.noProducts{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.noProducts h2 {
    color: white;
}
.cartsFlex{
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.cartsDirection{
    display: flex;
    width: 90%;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;


}
.cartProduct{
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 15px;
    align-items: center;

}
.leftProduct{
    display: flex;
    width: 50%;
    height: 121px;
    background-color: #303455;

}

.block{
    width: 20%;
    display: flex;
    align-items: center;
    z-index: 1;
    margin-left: 15px;
    margin-top: 20px;
}

.productsImage{
    width: 131px;
    height: 100%;
}

.productsImage img{
    width: 100%;
    height: 100%;
}
.productTitle h2{
    color: white;
}
.productTitle h3{
    display: none;
    color: white;
}

.deleteButton{
    display: flex;
    justify-self: end;
    align-self: center;
    flex-direction: column;
    margin-right: 15px;
    margin-left: auto;
}

.deleteButtonBlock{
    width: 90px;
    height: 30px;
    background-color: rgb(189, 78, 78);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
}
.downloadButton{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90px;
    height: 30px;
    background-color: rgb(12, 105, 131);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    list-style: none;
    /* margin-top: 10px; */
}
.reviewButton2{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90px;
    height: 30px;
    background-color: rgb(171, 163, 11);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    list-style: none;
    margin-top: 10px;
}
.reviewButton2Active{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 90px;
    height: 30px;
    background-color: rgb(48, 197, 71);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    list-style: none;
    margin-top: 10px;
}

@media (max-width:1200px){
    .block{
        display: none;

    }
    .cartsDirection{
        width: 100%;
    }
    .cartProduct{
        flex-direction: column;
    }
    .leftProduct{
        width: 68%;
    }
}

@media (max-width:621px){
    .leftProduct{
        width: 250px;
        min-height: 300px;
        flex-direction: column;
        align-items: center;
    }
    .deleteButton{
        margin-left: 0px;
        display: flex;
        align-content: center;
        justify-content: center;
        height: 35%;

    }
    .productsImage{
        height: 40%;
        margin-top: 10px;
    }
    .productsImage img{
        height: 100%;
    }
    
}

@media (max-width:420px){
    .productTitle:nth-child(1) h2{
        display: none;
    }
    .productTitle h2{
        word-break: break-all;
    }
    .productTitle h3{
        display: inline;
        margin-left: 0px;
    }
}