
.cookiesMain{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1111;
    position: fixed;
}


.weUseCookies{
    width: 100%;
    height: 100px;
    background-color: #3d426d;
    opacity: 1.3 !important;
    position: fixed;
    display: flex;
    justify-content: center;
}

.cookieTextDiv{
    width: 50%;
    word-break: break-all;
    height: 100%;
    display: flex;
    align-items: center;
}
.cookieTextDiv span{
    color: white;
}

.allowButtonDiv{
    width: 120px;
    height: 100%;
    display: flex;
    align-items: center;
}
.allowButton{
    background-color: #313661;
    width: 70px;
    height: 40px;
    cursor: pointer;
    color: white;
    margin-left: 15px;
}