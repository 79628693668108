

.main{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    margin-top: 100px;
}

.main_flex{
    display: flex;
    justify-content: center;
}
.first_section{
    display: flex;
    flex-direction: row;
    /* margin-top: -25px; */
    margin-left: 22px;
}
.mini_images{
    width: 100px;
    height: 85px;
    margin-bottom: 15px;
}
.addToCartFlex{
    display: flex;
}

.arrow_left{
    width: 50px;
    margin-left: -59%;
    height: 50px;
    filter: invert(50%);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    align-self: center;
    background-image: url('../../../../assets/img/arrow_left.png');
}
.arrow_right{
    width: 50px;
    height: 50px;
    filter: invert(50%);
    margin-left: 59%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    align-self: center;
    background-image: url('../../../../assets/img/arrow_right.png');
}
.product_flexPart{
    display: flex;
    justify-content: space-between;
}
.productOpenDescription{
    transition: 1s;
    width: 42px;
    height: 36px;
    transform: rotate(-90deg);
    filter: invert(100%);
    justify-self: flex-end;
    cursor: pointer;
}
.productOpenDescriptionRotate{
    transition: 1s;
    width: 42px;
    height: 36px;
    transform: rotate(90deg);
    filter: invert(100%);
    justify-self: flex-end;
    cursor: pointer;
}
.productInfo{
    margin-left: 8px;
    display: flex;
    width: 90%;
    margin-top: 10px;
}
.productText{
    color: #575756;
    margin-left: 5px;
}
.lock_logo{
    width: 20px;
    height: 20px;
}
.mini_images_img{
    width: 80px;
    cursor: pointer;
    height: 65px;
    border-radius: 15px;
}
.addCart{
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
}
.blockFlex{
    display: flex;
    width: 90%;
    background-color: #1f2238;
    border: 1px solid gray;
    flex-direction: column;
}
.productOtherInfo{
    display: flex;
    flex-direction: column;
}
.licensePart{
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
}
.licenseText{
    color: white;
    margin-left: 10px;
}
.opacity{
    opacity: 0.5;
}

.reviewPart{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.reviewImageText{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    min-height: 80px;
    max-height: 250px;
    margin-top: 10px;
}
.nameImagePart{
    display: flex;
    align-items: center;
}
.namePart{
    margin-left: 15px;
    color: white;
}

.textStarsBlock{
    display: flex;
    flex-direction: column;
}
.starRated{
    margin-left: 5px;
}
.reviewImage{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.reviewText{
    justify-self: center;
    word-break: break-all;
    margin-left: 8px;
    color: white;
}
.arrowLicense{
    transition: 1s;
    transform: rotate(270deg);
    width: 40px;
    height: 40px;
    filter: invert(100%);
    cursor: pointer;

}
.arrowLicenseRotate{
    transform: rotate(270deg);
    width: 40px;
    height: 40px;
    filter: invert(100%);
    cursor: pointer;
    transition: 1s;
    transform: rotate(90deg);
}
.addCartFlex{
    width: 20%;
    display: flex;
    height: 50%;
}
.pricePart{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
}
.priceText{
    color: white;
    margin-right: 10px;
}
.currentLicense{
    color: white;
    margin-left: 10px;
}
.cartButtonDiv{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cartButtonDivCenter{
    width: 100%;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}
.licenseMenu{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
}
.licenseInputsBlock{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}
.flexBlock{
    display: flex;
    align-items: center;
}
.flexBlock h3{
    margin-left: 10px;
}
.cartButtonStyle{
    width: 90%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: rgb(31, 146, 31);
}
.flexCheck{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cartButtonStyle:hover{
    background-color: rgb(27, 129, 27);

}
.addToCartText{
    color: white;
}
.cartImageButton{
    width: 30px;
    height: 40px;
    filter: invert(100%);
    margin-left: 15px;
}
.main_image{
    width: 98%;
    border-radius: 15px;

}

.main_image img{
    width: 100%;
    height: 600px;
    border-radius: 15px;
}
.second_section{
    width: 57%;
}
.second_section_swiper{
    display: none;
}
.second_section_swiper_length{
    display: flex;
    justify-content: center;
}
.third_section{
    display: flex;
    flex-direction: column;

    width: 250px;
    height: auto;


}

.arrow_down{
    width: 75px;
    height: 110px;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -15px;
}
.arrow_down img{
    transform: rotate(-180deg);
    filter: invert(50%);
    width: 50px;
    cursor: pointer;
}
.arrow_top{
    width: 75px;
    height: 110px;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_top img{
    transform: rotate(0deg);
    filter: invert(50%);
    width: 50px;
    cursor: pointer;

}
.item_title_width{
    width: 221px;
    margin-left: 15px;
    white-space: pre-wrap;
}
.item_title{
    width:91%;
    word-wrap: break-word;
    color: white;
}
.item_paragraph{
    margin-left: 15px;
    width: 85%;
    word-wrap: break-word;
    height: auto;
    color: white;
}
.active_element{
    border: 2px solid black;
}
.third_flex{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-left: 239px;
}
.third_section{
    width: 53%;
}
.item_paragraph{
    width: 100%;
}
.item_title_width{
    width: 100%;
}
.hrText{
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media (min-width:1921px){
    .second_section{
        width: 66%;
        height: 60%;

    }
    .main_image{
        height: auto;
        width: 100%;
    }
    .main_image img{
        height: 100%;
    } 
}
@media (max-width:1921px){
    .second_section{
        width: 66%;
        height: 60%;

    }
    .main_image{
        height: auto;
        width: 100%;
    }
    .main_image img{
        height: 100%;
    } 
    .third_section{
        width: 60%;
        float: left;
    }
}

@media(max-width:1553px){
    .second_section{
        width: 60%;
        height: 50%;
    }
    .main_image{
        height: auto;
        width: 100%;
    }
    .main_image img{
        height: 100%;
    }
    .third_section{
        width: 54%;
    }
    .addCartFlex{
        width: 27%;
    }
}

@media (max-width:1237px){
    .second_section{
        width: 60%;
        height: 100%;
    }
    .main_image{
        height: 65%;
    }
    .main_image img{
        height: 65%;
    }

}

/* @media(max-width:1001px){
    .second_section{
        width: 60%;
        height: 500px;
    }
    .main_image{
        height: 500px;
    }
    .main_image img{
        height: 500px;
    }
} */

@media(max-width:1118px){
    .addCartFlex{
        width: 26%;
    }
}

@media (max-width:950px){
    .main{
        height: 100%;
    }
    .main_flex{
        justify-self: center;
        flex-direction: column;
    }
    .second_section{
        display: flex;
        order: -1;
        width: 100%;
        height: 50%;
        justify-content: center;
    }
    .arrow_down{
        transform: rotate(0deg);
        margin-top: -20px;
        margin-left: -20px;
    }
    .addCartFlex{
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 15px;
    }
    .cartButtonStyle{
        margin-top: -10px;
    }
    .cartButtonDivCenter{
        margin-top: 0px;
        height: 117px;
    }
    .addCart{
        width: 50%;
        height: auto;
    }
    .arrow_top{
        /* transform: rotate(270deg); */
        transform: rotate(0deg);
        margin-top: -20px;
    }
    .main_image{
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .main_image img{
        width: 80%;
        height: 100%;
    }
    .third_flex{
        display: flex;
        width: 100%;
        justify-content: center;
        margin-left: 0px;
    }
    .first_flex{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .first_section{
        margin-top: 15px;
        /* justify-content: center; */
        width: 85%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }
    .third_section{
        width: 80%;
    }
    .item_title{
        width: 373px;
    }
    
}

@media(max-width:769px){
    .first_section{
        display: none;
    }
    .second_section{
        display: none;
        order: -1;
        width: 100%;
        height: 100%;

    }
    .second_section_swiper{
        display: flex;
        /* width: 100%;
        height: 50%; */
        justify-content: center;
    }
    .second_section_swiper_length{
        display: flex;

        justify-content: center;
    }

}
@media(max-width:599px){
    .item_title{
        width: 91%;
    }
}
@media(max-width:453px){
    .addCart{
        width: 70%;
    }
}
@media(max-width:422px){
    .second_section{
        display: none;
        order: -1;
        width: 100%;
        height: 100%;

    }
    .addCart{
        width: 100%;
    }
    .main_image{
        width: 269px;
        height: 179px;
        display: flex;
        justify-content: flex-end;
    }
    .main_image img{
        width: 269px;
        height: 179px;
    }
    .arrow_left{
        margin-right: 230px;
    }
    .arrow_right{
        margin-right: -10px;
        margin-left: 0px;
    }
}