

.main{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    margin-top: 100px;
}

.main_flex{
    display: flex;
    justify-content: center;
}
.first_section_portfolio{
    display: flex;
    flex-direction: column;
    /* margin-top: -25px; */
}
.mini_images{
    width: 100px;
    height: 85px;
    margin-bottom: 15px;
}
.addToCartFlex{
    display: flex;
}

.arrow_left{
    width: 50px;
    margin-left: -59%;
    height: 50px;
    filter: invert(50%);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    align-self: center;
    background-image: url('./../../../assets/img/arrow_left.png');
}
.arrow_right{
    width: 50px;
    height: 50px;
    filter: invert(50%);
    margin-left: 59%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    align-self: center;
    background-image: url('./../../../assets/img/arrow_right.png');
}
.mini_images_img{
    width: 80px;
    cursor: pointer;
    height: 65px;
    border-radius: 15px;
}

.main_image{
    width: 98%;
    border-radius: 15px;

}

.main_image img{
    width: 100%;
    height: 600px;
    border-radius: 15px;
}
.second_section{
    width: 57%;
}
.second_section_swiper{
    display: none;
}
.second_section_swiper_length{
    display: flex;
    justify-content: center;
}
.third_section{
    display: flex;
    flex-direction: column;

    width: 250px;
    height: auto;


}

.arrow_down_portfolio{
    width: 75px;
    height: 50px;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_down_portfolio img{
    transform: rotate(-90deg);
    filter: invert(50%);
    width: 50px;
    cursor: pointer;
}
.arrow_top_portfolio{
    width: 75px;
    height: 50px;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.arrow_top_portfolio img{
    transform: rotate(90deg);
    filter: invert(50%);
    width: 50px;
    cursor: pointer;

}
.item_title_width{
    width: 221px;
    margin-left: 15px;
    white-space: pre-wrap;
}
.item_title{
    width:91%;
    word-wrap: break-word;
    color: white;
}
.item_paragraph{
    margin-left: 15px;
    width: 85%;
    word-wrap: break-word;
    height: auto;
    color: white;
}
.active_element{
    border: 2px solid black;
}
.third_flex_portfolio{
    display: flex;
    width: 100%;
    justify-content: center;
}
.third_section{
    width: 53%;
}
.item_paragraph{
    width: 100%;
}
.item_title_width{
    width: 100%;
}
.hrText{
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 5px;
    margin-bottom: 5px;
}

@media (min-width:1921px){
    .second_section{
        width: 66%;
        height: 60%;

    }
    .main_image{
        height: auto;
        width: 100%;
    }
    .main_image img{
        height: 100%;
    } 
}
@media (max-width:1921px){
    .second_section{
        width: 66%;
        height: 60%;

    }
    .main_image{
        height: auto;
        width: 100%;
    }
    .main_image img{
        height: 100%;
    } 
    .third_section{
        width: 60%;
        float: left;
    }
}

@media(max-width:1553px){
    .second_section{
        width: 60%;
        height: 50%;
    }
    .main_image{
        height: auto;
        width: 100%;
    }
    .main_image img{
        height: 100%;
    }
    .third_section{
        width: 54%;
    }
}

@media (max-width:1237px){
    .second_section{
        width: 60%;
        height: 100%;
    }
    .main_image{
        height: 65%;
    }
    .main_image img{
        height: 65%;
    }

}

/* @media(max-width:1001px){
    .second_section{
        width: 60%;
        height: 500px;
    }
    .main_image{
        height: 500px;
    }
    .main_image img{
        height: 500px;
    }
} */

@media (max-width:950px){
    .main{
        height: 100%;
    }
    .main_flex{
        justify-self: center;
        flex-direction: column;
    }
    .second_section{
        display: flex;
        order: -1;
        width: 100%;
        height: 50%;
        justify-content: center;
    }
    .arrow_down_portfolio{
        transform: rotate(270deg);
        margin-top: 10px;
        margin-left: -20px;
    }
    .arrow_top_portfolio{
        transform: rotate(270deg);
        margin-top: 10px;
    }
    .main_image{
        width: 80%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
    .main_image img{
        width: 80%;
        height: 100%;
    }
    .third_flex_portfolio{
        display: flex;
        width: 100%;
        justify-content: center;
    }
    .first_flex{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .first_section_portfolio{
        margin-top: 15px;
        /* justify-content: center; */
        width: 65%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }
    .third_section{
        width: 65%;
    }
    .item_title{
        width: 373px;
    }
    
}

@media(max-width:769px){
    .first_section_portfolio{
        display: none;
    }
    .second_section{
        display: none;
        order: -1;
        width: 100%;
        height: 100%;

    }
    .second_section_swiper{
        display: flex;
        /* width: 100%;
        height: 50%; */
        justify-content: center;
    }
    .second_section_swiper_length{
        display: flex;

        justify-content: center;
    }

}
@media(max-width:599px){
    .item_title{
        width: 91%;
    }
}
@media(max-width:421px){
    .second_section{
        display: none;
        order: -1;
        width: 100%;
        height: 100%;

    }
    .main_image{
        width: 269px;
        height: 179px;
        display: flex;
        justify-content: flex-end;
    }
    .main_image img{
        width: 269px;
        height: 179px;
    }
    .arrow_left{
        margin-right: 230px;
    }
    .arrow_right{
        margin-right: -10px;
        margin-left: 0px;
    }
}