.contacts{
    background-color: #272b47;
    padding: 70px 0;

    @media screen and ( max-width:860px){
        padding: 30px 0;
    }

    &__box{
        display: flex;
        justify-content: space-between;

        @media screen and ( max-width:1297px){
            flex-wrap: wrap;

            .contacts__box-title{
                width: 100%;
                margin-bottom: 70px;

                @media screen and ( max-width: 860px){
                    margin-bottom: 30px;
                }
            }

            .contacts__form{
                width: 55%;
                margin: 0 auto;

                @media screen and ( max-width:769px){
                    width: 77%;          
                }
            }
        }
    }

    &__box-title{
        width: 44%;

        @media screen and ( max-width:1297px){
            text-align: center;
        }

        h2{
            color: #DBDEE3;
            font-size: 40px;
            font-weight: 600;
            line-height: 1.2;
            display: block;
            border-left: 3px solid #DBDEE3;
            padding-left: 30px;
            margin-bottom: 30px;

            @media screen and ( max-width:1297px){
                border-right: 3px solid #DBDEE3;
                padding-right: 30px;
            }

            @media screen and ( max-width:860px){
                font-size: 28px;
                padding: 0;
                margin-bottom: 22px;
            }
        }

        p{
            color: #DBDEE3;
            font-size: 18px;
            line-height: 1.67;

            @media screen and ( max-width:860px){
                font-size: 16px;
                color: #DBDEE3;            
            }
        }
    }

    &__form{
        width: 44%;
        color: #272b47;

        textarea{
            padding: 15px;
            width: 100%;
            resize: none;
            margin: 30px 0;
            cursor: pointer;
            background: #DBDEE3;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            font-size: 15px;
            font-family: Arial, Helvetica, sans-serif;
            
            &:focus{
                -webkit-box-shadow: 0px 0px 5px 1px #DBDEE3; 
                box-shadow: 0px 0px 5px 1px #DBDEE3;
            }

            &::placeholder{
                color: #272b47;
            }


            &:focus::placeholder{
                color: transparent;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
            }

            @media screen and ( max-width:1297px){
                margin: 15px 0;
            }

        }

        .btn-center{
            display: block;
            margin: 0 auto;
            background: #DBDEE3;
            color: #272b47;
            font-size: 15px;
            font-weight: 600;
            padding: 11px 22px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            cursor: pointer;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;

            &:hover{
                background: #272b47;
                color: #DBDEE3;
                -webkit-box-shadow: 0px 0px 5px 1px #DBDEE3; 
                box-shadow: 0px 0px 5px 1px #DBDEE3;
            }
        }

    }

    &__form-flex{
        display: flex;
        justify-content: space-between;

        input{
            background: #DBDEE3;
            padding: 15px;
            width: 17vw;
            cursor: pointer;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;

            &:focus{
                -webkit-box-shadow: 0px 0px 5px 1px #DBDEE3; 
                box-shadow: 0px 0px 5px 1px #DBDEE3;
            }
            
            &::placeholder{
                color: #272b47;
            }

            &:focus::placeholder{
                color: transparent;
                transition: all .5s ease;
                -webkit-transition: all .5s ease;
                -moz-transition: all .5s ease;
                -ms-transition: all .5s ease;
                -o-transition: all .5s ease;
            }

            @media screen and ( max-width:1297px){
                width: 21.5vw;
            }
            @media screen and ( max-width:769px){
                width: 29vw;
            }
            @media screen and ( max-width:671px){
                width: 35vw;
            }
        }

        input[name="name"],
        input[name="email"] {
            margin-bottom: 30px;

            @media screen and ( max-width:1297px){
                margin-bottom: 15px;
            }
        }
    }

    &__form-between{
        display: flex;
        flex-direction: column;
    }
}

.contacts__map{
    width: 100%;
    padding-top: 70px;

    @media screen and ( max-width:860px){
        padding-top: 30px;
    }
    iframe {
        width: 100%;
        height: 500px;
        border: 0;
        @media screen and (max-width: 925px) {
          height: 400px;
        }
        @media screen and (max-width: 550px) {
          height: 300px;
        }
      }
}