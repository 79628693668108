.header__main{
    position: sticky;
    width: 100%;
    z-index: 99;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    padding-top: 4px;
    background: #272b47;
    -webkit-box-shadow: 0px 3px 22px 3px rgba(39,43,71,0.59);
    box-shadow: 0px 3px 22px 3px rgba(39,43,71,0.59);
}

.header{
    padding: 7px 0;
    z-index: 11;
    
    &__nav{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .header__logo-title{
            display: flex;
            align-items: center;
            justify-content: center;
            color: #DBDEE3;
            font-size: 22px;
            line-height: 1px;
            font-family: monospace;
            font-weight: 700;
            margin-right: 35px;

            &:hover{
                .header__logo{
                    transform: rotate(-20deg);
                    -webkit-transform: rotate(-20deg);
                    -moz-transform: rotate(-20deg);
                    -ms-transform: rotate(-20deg);
                    -o-transform: rotate(-20deg);
                }
            }

            @media screen and ( max-width: 626px){
                margin-right: 0px;
                font-size: 18px;
            }
        }

        .header__logo{
            margin-right: 15px;
            width: 66px;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;

            @media screen and ( max-width: 626px){
                margin-right: 7px;
                width: 55px;
            }
        }

    }

    .header__right{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .header__rightUser{
        display: flex;
        align-items: center;
        justify-content: flex-end;

        width: 15%;
    }
    .userInfo{
        display: flex;
        width: 50px;

        @media screen and ( max-width: 1150px){
            margin-right: 52px;

        }
        @media screen and ( max-width: 400px){
            margin-right: 20px;

        }

    }
    .cartImage{
        width: 50px;
        filter: invert(100%);
        cursor: pointer;

        @media screen and ( max-width: 400px){
            width: 25px;

        }

    }
    .userImage{
        width: 50px;
        margin-left: 15px;
        border-radius: 50%;

        @media screen and ( max-width: 400px){
            width: 30px;

        }

    }
    
    .google_button{
        padding-right: 25px;
        margin-left: 35px;
    }
    *, *:before, *:after {
        box-sizing: border-box;
    }

    

    &__list{
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 90;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        
        & li:not(:last-child){
            margin-right: 15px;
        }

        .header__link{
            text-transform: uppercase;
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
            color: #DBDEE3;
            font-size: 13px;
            padding-bottom: 3px;
            margin-top: 5px;
            cursor: pointer;

            &:hover{
               color: #3390EF;
            }

            @media screen and ( max-width: 626px){
                font-size: 11px;
                padding-bottom: 0;
            }
        }

        .dropdown__li .header__link{
            display: flex;
            align-items: center;
            margin-top: 5px;

            @media screen and ( max-width: 1150px){
                margin-top: 0;
            }
        }

        .header__link-icon{
            font-size: 22px;
            color: #3390EF;
        }

        .games__dropdown{
            position: absolute;
            top: 58px;
            background: #272b47;
            display: none;
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
            -moz-transition: all .4s ease;
            -ms-transition: all .4s ease;
            -o-transition: all .4s ease;
            
            li{
                margin: 11px;
            }
        }
        
        .dropdown__li:hover .games__dropdown{
            display: block;
            padding-top: 11px;
        }
    }



    .header__burger{
        display: flex;
        flex-direction: column;
        margin-left: 22px;
        cursor: pointer;
        display: none;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;

        i{
            background-color: white;
            width: 30px;
            height: 2px;
            margin: 4px;
            border-radius: 2px;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -ms-border-radius: 2px;
            -o-border-radius: 2px;
        }

        .open,
        .close{
            transition: all .5s ease;
            -webkit-transition: all .5s ease;
            -moz-transition: all .5s ease;
            -ms-transition: all .5s ease;
            -o-transition: all .5s ease;
        }

        .open:nth-child(1){
            transform: rotate(45deg) translateY(14px);
            -webkit-transform: rotate(45deg) translateY(14px);
            -moz-transform: rotate(45deg) translateY(14px);
            -ms-transform: rotate(45deg) translateY(14px);
            -o-transform: rotate(45deg) translateY(14px);
        }

        .open:nth-child(2){
            opacity: 0;
            transition: all .1s ease;
            -webkit-transition: all .1s ease;
            -moz-transition: all .1s ease;
            -ms-transition: all .1s ease;
            -o-transition: all .1s ease;
        }

        .open:nth-child(3){
            transform: rotate(-45deg) translateY(-14px);
            -webkit-transform: rotate(-45deg) translateY(-14px);
            -moz-transform: rotate(-45deg) translateY(-14px);
            -ms-transform: rotate(-45deg) translateY(-14px);
            -o-transform: rotate(-45deg) translateY(-14px);
        }

        .close:nth-child(1){
            transform: rotate(0) translateY(0);
            -webkit-transform: rotate(0) translateY(0);
            -moz-transform: rotate(0) translateY(0);
            -ms-transform: rotate(0) translateY(0);
            -o-transform: rotate(0) translateY(0);
        }

        .close:nth-child(2){
            opacity: 1;
        }

        .close:nth-child(3){
            transform: rotate(0) translateY(0);
            -webkit-transform: rotate(0) translateY(0);
            -moz-transform: rotate(0) translateY(0);
            -ms-transform: rotate(0) translateY(0);
            -o-transform: rotate(0) translateY(0);
        }

    }

    .header__form{
        display: flex;
        align-items: center;
        justify-content: center;
        
    
        .header__input{
            background: none;
            color: white;
            padding: 12px 10px;
            font-size: 13px;
            background: #0e0f11;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: all .9s ease;
            -webkit-transition: all .9s ease;
            -moz-transition: all .9s ease;
            -ms-transition: all .9s ease;
            -o-transition: all .9s ease;
        }

        .click {
            opacity: 1;
            z-index: 1;
            visibility: visible;
            background: none;
            color: white;
            padding: 12px 10px;
            font-size: 13px;
            background: #0e0f11;
            transition: all .9s ease;
            -webkit-transition: all .9s ease;
            -moz-transition: all .9s ease;
            -ms-transition: all .9s ease;
            -o-transition: all .9s ease;
        }

        ::placeholder{
            color: white;
            font-size: 13px;
        }

        .header__btn{
            background: transparent;
            cursor: pointer;
            margin-left: -30px;
            z-index: 2;
        }

        .header__btn:before{
            content: "\f002";
            font-family: FontAwesome;
            color: white;
            font-size: 20px;
        }
    }

    .header__icons{
        margin-left: 22px;
    }

    .header__sign{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        cursor: pointer;
        font-size: 15px;
        text-transform: uppercase;
        transition: all .5s ease;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
        -ms-transition: all .5s ease;
        -o-transition: all .5s ease;
        border-bottom: 2px solid transparent;
        padding-bottom: 3px;

        &:hover{
            border-bottom: 2px solid #3390EF;
        }
    }

    .header__sign-p{
        margin-left: 5px;
    }

    // Media
    @media screen and (max-width: 1150px){
    .header__burger{
        display: flex;
        padding-left: 0;
    }

    .header__list{
        flex-direction: column;
        position: absolute;
        text-align: center;
        top: 84px;
        left: -100%;
        background: #272b47;
        width: 22%;
        -webkit-box-shadow: 20px 20px 50px -20px rgba(219,222,227,0.33); 
        box-shadow: 20px 20px 50px -20px rgba(219,222,227,0.33);
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);

        & li{
            width: 100%;
            padding: 7px 0;

            &:not(:last-child){
                margin-right: 0;
            }

            @media screen and ( max-width: 626px){
                padding: 5px 0;
            }
        }

        .dropdown__li .header__link{
            display: inline-flex;
        }
    }

    .header__list-active{
        position: absolute;
        top: 83px;
        left: 0%;
        z-index: 1;
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
        -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
        -o-transform: translateX(0%);

        @media screen and ( max-width: 626px){
            top: 72px;
            width: 55%;
        }
    }

    .games__dropdown{
        position: relative;
        top: 0;
        // border-top: 1px solid #DBDEE3;
        // border-bottom: 1px solid #DBDEE3;
        
        li{
            margin: 0px;
        }
    }
    
    .dropdown__li:hover .games__dropdown{
        display: block;
        padding-top: 0px;
    }
    }


    @media screen and ( max-width: 540px){
        .header__burger{
            margin-left: 11px;
        }
    }

    @media screen and ( max-width: 415px){
        .header__icons{
            position: absolute;
            align-items: center;
            text-align: center;
            justify-content: center;
            top: 75px;
            right: 2px;
            padding: 10px;
            border-radius: 7px;
            background: rgba(255, 255, 255, 0.219);
            -webkit-box-shadow: 0px 7px 33px 7px rgba(39,43,71,0.59); 
            box-shadow: 0px 7px 33px 7px rgba(39,43,71,0.59);

            .white{
                font-size: 20px;
                color: #272d47;
                margin-right: 2px;
            }
        }

    }
}

.sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #272b47;
    -webkit-box-shadow: 0px 3px 22px 3px rgba(39,43,71,0.59);
    box-shadow: 0px 3px 22px 3px rgba(39,43,71,0.59);
    z-index: 99;
    padding-top: 4px;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
}