


.main_admin{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
    padding-top: 10px 0 0 10px;
}


.admin_inputss{
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.flex_direction_class{
    display: flex;
    flex-direction: column;
}
.admin_input1 input{
    width: 500px;
    height: 40px;
    margin-top: 15px;
}
.admin_textarea textarea{
    width: 500px;
    margin-top: 15px;
}
.admin_file_input input{
    margin-top: 15px;
}
.create_admin_post button{
    margin-top: 15px;
    width: 90px;
    height: 40px;
    cursor: pointer;
}
.admin_file_input{
    display: flex;
    flex-direction: column;
}

.select_category{
    margin-top: 15px;
}

.centered_div{
    display: flex;
    width: 500px;
    justify-content: center;
}
.second_photo_label{
    margin-top: 15px;
}
.succses_upload{
    color: rgb(12, 216, 12);
    font-size: 18;
}
.reject_upload{
    color: red;
    font-size: 18;
}
.delete_button{
    cursor: pointer;
}
.flex_direction_class{
    display: flex;
    flex-direction: column;
}

.login_block{
    display: flex;
    justify-content: center;
}
.login_block_flex{
    display: flex;
    flex-direction: column;
}
.login_button{
    margin-top: 15px;
    
}
.content_buttons{
    display: flex;
    margin-top: 15px;
}
.edit_button{
    margin-left: 15px;
    cursor: pointer;
}
.color_white{
    color: white;
}
.marginTop{
    margin-top: 15px;
}
.content____img{
    width: 500px;
}

.editedImages{
    display: flex;
    flex-direction: row;
    width: 80%;
    flex-wrap: wrap;
}

.imageMiniForEdit{
    width: 20%;
    margin-left: 15px;
    margin-top: 10px;
}