

.loginPopup{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.322);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1111;
    position: fixed;
}


.popup{
    width: 400px;
    height: 300px;
    background-color: #3d426d;
    border-radius: 10px;
    opacity: 1.3 !important;
    position: fixed;
    display: flex;
    flex-direction: column;
}

.topPart{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 27%;
}
.logoImg{
    width: 15%;
    height: 70%;
}
.logoText{
    color: #DBDEE3;
    font-size: 22px;
    line-height: 1px;
    font-family: monospace;
    font-weight: 700;
    margin-left: 20px;
}
.centerPart{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.closeImg{
    width: 10%;
    height: 35%;
    align-self: flex-start;
    justify-self: flex-end;
    margin-right: -8px;
    filter: invert(75%);
    cursor: pointer;
}
.closeImg img{
    cursor: pointer;
}
.centerFlex{
    width: 100%;
    height: 100%;
}
.google_button{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.g-sign-in-button {
    margin: 10px;
    display: inline-block;
    width: 190px;
    height: 50px;
    background-color: #4285f4;
    color: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.g-sign-in-button:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.g-sign-in-button:active {
    background-color: #3367D6;
    transition: background-color 0.2s;
}

.g-sign-in-button .content-wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
    display: flex;
}

.g-sign-in-button img {
    width: 18px;
    height: 18px;
}

.g-sign-in-button .logo-wrapper {
    background: #fff;
    width: 54px;
    height: 100%;
    border-radius: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.g-sign-in-button .text-container {
    font-family: Roboto,arial,sans-serif;
    font-weight: 500;
    letter-spacing: .21px;
    font-size: 16px;
    line-height: 48px;
    vertical-align: top;
    border: none;
    display: inline-block;
    text-align: center;
    width: 180px;
}

.g-logout-button {
    margin: 10px;
    display: inline-block;
    width: 210px;
    height: 50px;
    background-color: #4285f4;
    color: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color .218s, border-color .218s, box-shadow .218s;
}

.g-logout-button:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.g-logout-button:active {
    background-color: #3367D6;
    transition: background-color 0.2s;
}

.g-logout-button .content-wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
    display: flex;
}

.g-logout-button img {
    width: 18px;
    height: 18px;
}

.g-logout-button .logo-wrapper {
    background: #fff;
    width: 54px;
    height: 100%;
    border-radius: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.g-logout-button .text-container {
    font-family: Roboto,arial,sans-serif;
    font-weight: 500;
    letter-spacing: .21px;
    font-size: 16px;
    line-height: 48px;
    vertical-align: top;
    border: none;
    display: inline-block;
    text-align: center;
    width: 180px;
}





@media (max-width:485px){
    .popup{
        width: 300px;
        height: 200px;
    }
    .logoImg{
        width: 18%;
    }
}